<script>
  const aboutData = {
    headline: `Building digital avenues to communicate with your audience.`,
    paragraph1:
      "Hi, I'm Mark. A Web Developer based in West Lothian,\
      Scotland. Providing digital solutions to startups and SME's.\
      I have experience working with businesses from various industries and \
      gaining an understanding about their needs. \
      "
  };
</script>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

  #about {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 60px 50px;
    width: 80%;
  }

  h1 {
    font-size: 2.6em;
    font-weight: bold;
    position: relative;
  }

  h1::after {
    content: "";
    position: absolute;
    width: 7%;
    height: 1px;
    top: 116%;
    left: 0;
    border-bottom: 5px solid #ee4700;
  }

  p {
    font-size: 1.25em;
    font-weight: 400;
    display: flex;
    line-height: 1.5em;
    margin-top: 26px;
  }

  @media (min-width: 426px) and (max-width: 768px) {
    #about {
      padding: 30px 10px;
      flex-direction: column;
    }
    h1 {
      font-size: 1.6em;
      width: 100%;
      padding: 0;
    }
    h1::before {
      top: 120%;
    }
    p {
      padding: 10px 0;
      width: 100%;
    }
  }
  @media (min-width: 320px) and (max-width: 425px) {
    #about {
      padding: 30px 10px;
      flex-direction: column;
    }
    h1 {
      font-size: 1.6em;
      width: 100%;
      padding: 0;
    }
    h1::before {
      top: 120%;
    }
    p {
      padding: 10px 0;
      width: 100%;
    }
  }
</style>

<div id="about">
  <h1>{aboutData.headline}</h1>
  <p>{aboutData.paragraph1}</p>
</div>
