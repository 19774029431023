<script>
  import Service from "./Service.svelte";
  const serviceList = [
    { headline: "Web Design", image: "/img/webIcon.png" },
    { headline: "Branding", image: "/img/brandIcon.png" },
    { headline: "App Development", image: "/img/phoneIcon.png" }
  ];
</script>

<style>
  .service-row {
    display: flex;
    margin-top: 30px;
    padding: 0 50px;
  }

  /* Tablet Styles */
  @media (min-width: 421px) and (max-width: 768px) {
    .service-row {
      width: 100%;
    }
  }

  /* Mobile Styles */
  @media (min-width: 320px) and (max-width: 420px) {
    .service-row {
      display: inline-block;
      width: 100%;
      padding: 0;
    }
  }
</style>

<div class="service-row">
  {#each serviceList as service}
    <Service header={service.headline} image={service.image} />
  {/each}
</div>
