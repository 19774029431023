<script>
  import Navbar from "./Navbar.svelte";
</script>

<style>
  .header {
    background: url("/img/nav-bg.png");
    display: inline-flex;
    justify-content: space-around;
    padding: 20px;
    width: 100%;
    margin: 0;
    background-position-x: -40px;
    background-repeat: round;
  }
  .logo {
    width: 80px;
    height: 60px;
  }

  /* Mobile Styles */
  @media (min-width: 320px) and (max-width: 430px) {
    .header {
      display: inline-block;
      padding: 0;
      padding-top: 20px;
      background: #ee4700;
    }
    .logo {
      display: block;
      margin: 0 auto;
    }
  }
</style>

<div class="header">
  <img src="/img/mklogo-white.png" class="logo" alt="logo" width="50" />
  <Navbar />
</div>
