<script>
  import ServiceList from "./Servicelist.svelte";
</script>

<style>
  #services {
    padding: 60px 0;
    margin: 0 auto;
  }
  h2 {
    text-align: center;
    font-size: 2.6em;
    font-weight: bold;
    position: relative;
  }

  h2::after {
    content: "";
    position: absolute;
    width: 7%;
    height: 1px;
    top: 120%;
    left: 47%;
    border-bottom: 5px solid #ee4700;
  }

  @media (min-width: 320px) and (max-width: 420px) {
    h2 {
      font-size: 1.6em;
    }
  }
</style>

<div id="services">
  <h2>What I can do for your business</h2>
  <ServiceList />
</div>
