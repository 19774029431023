<script>

</script>

<style>
  #footer {
    padding: 30px;
    background-image: url("/img/footer-bg.png");
  }
  h3 {
    font-size: 1.4em;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
  }
  h4 {
    color: #ffffff;
    font-weight: 400;
    text-align: center;
  }
  .connectIcons {
    text-align: center;
  }
  .connectIcon {
    width: 30px;
    margin: 5px;
    transition-duration: 300ms;
  }

  .connectIcon:hover {
    transform: scale(1.2);
  }

  @media (min-width: 320px) and (max-width: 420px) {
    #footer {
      width: 100%;
      padding: 20px 15px;
    }
  }
</style>

<div id="footer">
  <h3>Connect with me</h3>
  <div class="connectIcons">
    <a href="https://www.github.com/kingy86" target="_blank">
      <img src="/img/githubIcon.png" class="connectIcon" alt="logo" />
    </a>
    <a href="https://www.linkedin.com/in/mark-c-king/" target="_blank">
      <img src="/img/linkedinIcon.png" class="connectIcon" alt="logo" />
    </a>
    <a href="mailto:mark.c.king@zoho.com">
      <img src="/img/mailIcon.png" class="connectIcon" alt="logo" />
    </a>
  </div>

  <h4>Copyright 2022</h4>
</div>
