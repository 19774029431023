<script>
  export let header;
  export let image;
</script>

<style>
  .single-service {
    width: 33%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .service-icon {
    transition-duration: 500ms;
    color: #c45062;
    width: 90px;
    margin-bottom: 20px;
  }

  span {
    font-weight: 600;
    font-size: 2.1em;
  }

  /* Mobile Styles */
  @media (min-width: 320px) and (max-width: 420px) {
    .single-service {
      margin: 0 auto;
      width: 50%;
    }
    span {
      font-size: 1.4em;
    }
  }
</style>

<div class="single-service">
  <img src={image} alt="service-icon" class="service-icon" />
  <span>{header}</span>
</div>
